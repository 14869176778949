import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { LanguageProvider } from "../context/LanguageContext";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => (
  <LanguageProvider value="eng">
    <Helmet>
      <script src="https://gumroad.com/js/gumroad.js" />
    </Helmet>
    <Header />
    <div>
      <main>{children}</main>
    </div>
    <Footer />
  </LanguageProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
