import React from "react";
import { Link } from "gatsby";
import Logo from "../images/logos/Rosso.svg";
import "./globals.css";
import { useLanguage } from "../context/LanguageContext";

const Header = () => {
  const { language, changeLanguage } = useLanguage();
  const toggleLanguage = () => {
    const newLanguage = language === "en" ? "tr" : "en";
    changeLanguage(newLanguage);
  };

  const navigation = [
    { name: language === "en" ? "About" : "Hakkımızda", href: "#about" },
    { name: "Rosso Laguna", href: "#rossolaguna" },
    {
      name: language === "en" ? "Services" : "Hizmetlerimiz",
      href: "#services",
    },
    {
      name: language === "en" ? "Our Work" : "Çalışmalarımız",
      href: "#ourwork",
    },
  ];
  return (
    <header>
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <div className="flex flex-row gap-8 items-center">
            <Link to="/">
              <img
                className="h-[125px] sm:h-[165px] w-auto"
                src={Logo}
                alt="Logo"
              />
            </Link>
          </div>
          <div className="flex flex-row gap-6">
            <div className="md:flex hidden flex-row gap-4 items-center">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-body-sm font-medium text-neutral-700 hover:text-primary-600 px-4"
                >
                  {item.name}
                </a>
              ))}
            </div>
            <div class="switch">
              <input
                id="language-toggle"
                class="check-toggle check-toggle-round-flat"
                type="checkbox"
                onClick={toggleLanguage}
              />
              <label for="language-toggle"></label>
              <span class="on">TR</span>
              <span class="off">EN</span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
